<template>
  <div>
    <b-overlay
      :show="isSaving"
    >
      <b-card>
        <b-container fluid>
          <b-row>
            <b-col
              md="12"
            >
              <b-form id="customForm">
                <b-row>
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      label="Libellé de la question"
                    >
                      <b-form-input
                        id="libelle"
                        v-model="question.libelle"
                        placeholder="Libellé"
                        name="libelle"
                      />

                    </b-form-group>
                    <b-form-group
                      label="Catégorie de la question"
                    >
                      <b-form-select
                        v-model="question.categorieId"
                        :options="categories"
                      />

                    </b-form-group>
                    <b-form-group
                      label="Media joint à la question"
                    >
                      <b-form-file
                        ref="media_question"
                        accept=".png,.jpg,.jpeg"
                        name="media_question"
                        @change="formatBase64Question($event)"
                      />

                    </b-form-group>

                    <b-form-group
                      label="Vidéo jointe à la question"
                    >
                      <b-form-input
                        id="video_question"
                        v-model="video_question"
                        placeholder="Lien de la vidéo"
                        name="video_question"
                      />

                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <div class="text-center">
                      <b-overlay
                        :show="question.showOverlayMedia"
                        rounded="sm"
                      >
                        <img
                          :src="question.media_question"
                          alt=""
                          style="width: 200px"
                          @mouseover="activeOverlay('media_question')"
                        >
                        <template #overlay>
                          <div
                            class="d-inline-flex align-middle"
                            @mouseleave="desactiveOverlay('media_question')"
                          >
                            <b-badge
                              variant="danger"
                              class="m-1 w-50"
                            >
                              <feather-icon
                                icon="Trash2Icon"
                                size="30"
                                @click="removeFile('media_question')"
                              />
                            </b-badge>
                          </div>
                        </template>
                      </b-overlay>

                      <b-embed
                        v-if="question.video_question"
                        type="iframe"
                        aspect="16by9"
                        :src="question.video_question"
                        allowfullscreen
                      />
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-row cols="2">
                      <b-col md="6">
                        <h4>Compétences</h4>
                        <v-select
                          v-model="competenceLibelleList"
                          multiple
                          :options="getCompetenceLibelleList"
                        />
                      </b-col>
                      <b-col md="6">
                        <h4>Fonctions</h4>
                        <v-select
                          v-model="fonctionLibelleList"
                          multiple
                          :options="getFonctionLibelleList"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <vue-good-table
                      :columns="columns"
                      :rows="rows"
                      :pagination-options="{
                        enabled: false,
                      }"
                    >
                      <template
                        slot="table-row"
                        slot-scope="props"
                      >
                        <span
                          v-if="props.column.field == 'libelle'"
                        >
                          <b-form-file
                            :ref="props.row.originalIndex"
                            accept=".png,.jpg,.jpeg"
                            :name="'media_question' + '_' + props.row.originalIndex"
                            hidden
                            style="display: none;"
                            @change="formatBase64File($event, props.row.originalIndex )"
                          />
                          <b-form-input
                            v-model="rows[props.row.originalIndex].libelle"
                          />
                        </span>
                        <span
                          v-else-if="props.column.field == 'lien_media'"
                        >
                          <div
                            v-if="rows[props.row.originalIndex].lien_media"
                          >
                            <b-overlay
                              :show="rows[props.row.originalIndex].showOverlay"
                              rounded="sm"
                            >
                              <img
                                :ref="'image_'+ props.row.originalIndex"
                                :src="rows[props.row.originalIndex].lien_media"
                                style="width: 120px"
                                @mouseover="activeOverlay(props.row.originalIndex)"
                              >
                              <template #overlay>
                                <div
                                  class="d-inline-flex align-middle"
                                  @mouseleave="desactiveOverlay(props.row.originalIndex)"
                                >
                                  <b-badge
                                    variant="danger"
                                    class="m-1 w-50"
                                  >
                                    <feather-icon
                                      icon="Trash2Icon"
                                      size="30"
                                      @click="removeFile(props.row.originalIndex)"
                                    />
                                  </b-badge>
                                  <b-badge
                                    variant="primary"
                                    class="m-1 w-50"
                                    @click="uploadFile(props.row.originalIndex)"
                                  >
                                    <feather-icon
                                      icon="UploadIcon"
                                      size="30"
                                    />
                                  </b-badge>
                                </div>
                              </template>
                            </b-overlay>
                          </div>
                          <div
                            v-else
                          >
                            <b-button
                              id="detailIcon"
                              variant="primary"
                              @click="uploadFile(props.row.originalIndex)"
                            >
                              <feather-icon
                                icon="UploadIcon"
                              />
                            </b-button>
                          </div>

                        </span>
                        <span v-else-if="props.column.field == 'correct'">
                          <b-form-checkbox
                            v-model="rows[props.row.originalIndex].correct"
                            class="mr-0 mt-50"
                            switch
                            inline
                          />
                          <b-badge
                            v-if="props.formattedRow[props.column.field]"
                            variant="success"
                          >
                            Oui
                          </b-badge>
                          <b-badge
                            v-else
                            variant="danger"
                          >
                            Non
                          </b-badge>
                        </span>
                      </template>
                    </vue-good-table>
                  </b-col>
                </b-row>
                <b-row>
                  <hr>
                  <b-col class="text-right">
                    <b-button
                      variant="danger"
                      class="mb-1"
                      @click="cancelQuestion()"
                    >
                      Annuler
                    </b-button>
                  </b-col>
                  <b-col>
                    <b-button
                      variant="primary"
                      class="mb-1"
                      @click="saveQuestion()"
                    >
                      Enregistrer
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-col>
          </b-row>
        </b-container>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BBadge,
  BButton,
  BCol,
  BRow,
  BForm,
  BFormInput,
  BFormGroup,
  BFormFile,
  BContainer,
  BFormCheckbox,
  BOverlay,
  BFormSelect,
  BEmbed,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'

export default {
  components: {
    BOverlay,
    BCard,
    BBadge,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BCol,
    BRow,
    BFormFile,
    BContainer,
    BFormCheckbox,
    VueGoodTable,
    BFormSelect,
    vSelect,
    BEmbed,
  },
  data() {
    return {
      isSaving: false,
      showOverlay: false,
      editionMode: false,
      idQuestion: null,
      question: {
        categorieId: null,
        fonctions: [],
        competences: [],
        libelle: '',
        media_question: '',
        video_question: '',
        showOverlayMedia: false,
        actif: 1,
      },
      video_question: '',
      fonctions: [],
      fonctionLibelleList: [],
      competences: [],
      competenceLibelleList: [],
      columns: [
        {
          label: 'Label',
          field: 'label',
          tdClass: 'text-center align-middle',
          thClass: 'text-center',
          width: '10%',
        },
        {
          label: 'Libelle',
          field: 'libelle',
          tdClass: 'text-center align-middle',
          thClass: 'text-center',
          width: '60%',
        },
        {
          label: 'Lien média réponse', field: 'lien_media', tdClass: 'text-center align-middle', thClass: 'text-center', width: '15%',
        },
        {
          label: 'Réponse correcte ?', field: 'correct', type: 'boolean', tdClass: 'text-center align-middle', thClass: 'text-center', width: '15%',
        },
      ],
      rows: [
        {
          label: 'Réponse 1',
          libelle: '',
          lien_media: '',
          correct: false,
          showOverlay: false,
        },
        {
          label: 'Réponse 2',
          libelle: '',
          lien_media: '',
          correct: false,
          showOverlay: false,
        },
        {
          label: 'Réponse 3',
          libelle: '',
          lien_media: '',
          correct: false,
          showOverlay: false,
        },
        {
          label: 'Réponse 4',
          libelle: '',
          lien_media: '',
          correct: false,
          showOverlay: false,
        },
      ],
    }
  },
  watch: {
    video_question() {
      this.question.video_question = ''

      if (this.video_question !== '' && this.video_question !== null) {
        const id_video = this.video_question.replace('https://www.youtube.com/watch?v=', '').replace('https://youtu.be/', '').replace('https://www.youtube.com/embed/', '')
        if (id_video !== '') {
          this.video_question = `https://www.youtube.com/embed/${id_video}`
          this.question.video_question = `https://www.youtube.com/embed/${id_video}`
        }
      }
    },
  },
  computed: {
    getFonctionLibelleList() {
      return [...new Set(this.fonctions.map(el => el.libelle_fonction))]
    },
    getCompetenceLibelleList() {
      return [...new Set(this.competences.map(el => el.libelle_competence))]
    },
    categories() {
      // eslint-disable-next-line array-callback-return
      // eslint-disable-next-line arrow-body-style
      return this.$store.getters['questionReponse/categories']?.map(elem => {
        return {
          value: elem.id,
          text: elem.libelle_categorie,
        }
      })
    },
  },
  beforeMount() {
    if (this.$route.params.id !== undefined) {
      this.editionMode = true
      this.idQuestion = this.$route.params.id
      this.$store.dispatch('questionReponse/getQuestion', { id: this.$route.params.id }).then(question => {
        this.question.categorieId = question.categorieId

        this.fonctionLibelleList = question.fonctions.split(',')
        this.competenceLibelleList = question.competences.split(',')

        this.question.libelle = question.libelle
        this.question.actif = question.actif
        this.question.media_question = question.media_question
        this.question.video_question = question.video_question
        this.video_question = question.video_question

        question.reponseQuestion.forEach((reponse, index) => {
          this.rows[index].libelle = reponse.reponse.libelle
          this.rows[index].correct = reponse.correct
          this.rows[index].lien_media = reponse.reponse.lien_media
        })
      })
    }

    // this.question = this.$store.getters['questionReponse/questionTemplate']
    this.$store.dispatch('fonctionCompetence/getAllCompetences', { actif: 'Actif' }).then(
      data => {
        this.competences = data
      },
    )
    this.$store.dispatch('fonctionCompetence/getAllFonctions', { actif: 'Actif' }).then(
      data => {
        this.fonctions = data
      },
    )
    this.$store.dispatch('questionReponse/getAllCategories').then()
  },
  methods: {
    /**
     * lance une modale générique de succès ou d'erreur
     * @param {string} type 'success' ou 'error'
     * @author Francisco Fernandez <ffernandez@absystech.fr>
     */
    alertModal(type) {
      const props = {
        title: '',
        text: '',
      }
      if (type === 'success') {
        props.title = 'Enregistrement effectué'
        props.text = 'La question et ses réponses ont été enregistrées'
      } else {
        props.title = "Echec de l'enregistrement"
        props.text = "Quelque chose s'est mal passée et a empêché l'enregistrement"
      }
      this.$swal({
        title: props.title,
        text: props.text,
        icon: type,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.$router.push({ name: 'admin-questions-reponses' })
      })
    },
    cancelQuestion() {
      this.$router.push({ name: 'admin-questions-reponses' })
    },
    saveQuestion() {
      this.isSaving = true
      this.question.competences = this.competenceLibelleList.join(',')
      this.question.fonctions = this.fonctionLibelleList.join(',')

      if (this.editionMode === true) {
        // On passe la question en inactif
        // On crée ensuite la question
        this.$store.dispatch('questionReponse/updateQuestion', { id: this.idQuestion, actif: 0, archive: 1 }).then(() => this.createQuestion())
      } else {
        this.createQuestion()
      }
    },
    createQuestion() {
      const data = this.question
      delete data.showOverlayMedia

      this.$store.dispatch('questionReponse/postQuestion', data).then(
        question => {
          const reponses = []
          this.rows.forEach(rep => {
            if (rep.libelle !== '') {
              reponses.push(rep)
            }
          });
          this.$store.dispatch('questionReponse/postBulkReponses', reponses).then(
            reponses => {
              const quesRep = []
              reponses.forEach(reponse => {
                quesRep.push(
                  {
                    reponseId: reponse.id,
                    questionId: question.id,
                    correct: this.rows.filter(row => row.libelle === reponse.libelle)[0].correct,
                  },
                )
              })
              this.$store.dispatch('questionReponse/postBulkQuestionsReponses', quesRep).then(
                questionsReponses => {
                  this.isSaving = false
                  this.alertModal('success')
                  this.question.competences = this.question.competences.split(',')
                  this.question.fonctions = this.question.fonctions.split(',')
                },
              )
            },
          )
        },
      )
    },
    activeOverlay(index) {
      if (index === 'media_question') {
        this.question.showOverlayMedia = true
      } else {
        this.rows[index].showOverlay = true
      }
    },
    desactiveOverlay(index) {
      if (index === 'media_question') {
        this.question.showOverlayMedia = false
      } else {
        this.rows[index].showOverlay = false
      }
    },
    onShown(index) {
      const reference = `image_${index}`
      this.$refs[reference].hover()
    },
    uploadFile(reference) {
      this.$refs[reference].$refs.input.click()
    },
    formatBase64File(event, index) {
      // eslint-disable-next-line prefer-destructuring
      this.rows[index].lien_media = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.rows[index].lien_media)
      reader.onload = () => {
        this.rows[index].lien_media = reader.result
      }
    },
    formatBase64Question(event) {
      // eslint-disable-next-line prefer-destructuring
      this.question.media_question = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.question.media_question)
      reader.onload = () => {
        this.question.media_question = reader.result
      }
    },
    removeFile(index) {
      if (index === 'media_question') {
        // eslint-disable-next-line prefer-destructuring
        this.question.media_question = ''
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.rows[index].lien_media = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/libs/vue-sweetalert.scss';

#customForm {
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: stretch;
}
.checkbox-container {
  // border: 1px solid pink;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .checkbox-item {
    margin-right: 10%;
    margin-bottom: 5%;
  }
}

</style>
